import { useEffect, useRef, useState } from "react";
import { adminMembers } from "../services";
import { MemberListDTOTransformer } from "../utils/DTO/MemberListDTO";

const generateFilterString = (filterObj: { field: string; value: number[] | number; operator: string }) => {
  //Should return an string like field.operator.value1.value2
  const generateValues = (value: number[] | number) => {
    if (Array.isArray(value)) {
      return value.join(".");
    }
    return value;
  };
  return `${filterObj.field}.${filterObj.operator}.${generateValues(filterObj.value)}`;
};

export const usePagination = ({ hitsPerPage, requestPage, status, filterValues, ...props }) => {
  const [hits, setHits] = useState<Object[]>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState(requestPage);
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState(0);
  const isEditing = useRef(false);
  const [reloadCount, setReloadCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("MemberPainArea.last_response");

  interface Filter {
    status: string;
    coach: string;
    custom_filter?: string;
    order_by: string;
    order: string;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        let filter: Filter = {
          status,
          coach: props.filterCoach || undefined,
          order,
          order_by: orderBy,
        };

        if (filterValues) {
          const customFilter = generateFilterString(filterValues);
          filter = { ...filter, custom_filter: customFilter };
          isEditing.current = true;
        }
        if (text) {
          filter = {
            ...filter,
          };
        }

        setLoading(true);
        let result;
        try {
          result = await adminMembers.get("", {
            ...filter,
            page: page || 1,
            search: text,
            page_size: String(hitsPerPage),
          });
          result = new MemberListDTOTransformer(result?.data, status).getModel();
        } catch (error) {
          console.error("Error getting members");
          console.error(error);
        } finally {
          setLoading(false);
        }

        setCount(result.Count);
        const calculatePages = Math.ceil(Math.max(result.Count / result.PageSize, 1));
        setPages(calculatePages);

        const items = result.Items ?? [];
        const data =
          items.map(el => ({
            ...el,
            name: `${el.firstName} ${el.lastName}`,
          })) || [];
        setHits(data);
      } catch (e) {
        console.info(e);
      }
    };

    fetchData();

    return () => {
      try {
        adminMembers.cancelRequest();
        console.log("REQUEST CANCELED");
      } catch (e) {
        console.error("REQUEST CANCELED", e);
      }
    };
  }, [page, reloadCount, filterValues, props.filterCoach, isEditing.current, order, orderBy]);

  useEffect(() => {
    isEditing.current = true;
  }, [props.filterCoach]);

  const search = (query: string) => {
    setPage(1);
    isEditing.current = !isEditing.current;
    setText(query);
  };

  const actionSearch = () => {
    setPage(1);
    setReloadCount(r => r + 1);
  };

  const next = () => {
    isEditing.current = false;
    setPage(p => p + 1);
  };

  const reset = () => {
    isEditing.current = true;
    setReloadCount(r => r + 1);
  };

  return {
    search,
    actionSearch,
    filter: text,
    page,
    setPage,
    pages,
    count,
    next,
    reset,
    hits,
    loading,
    setOrderBy,
    setOrder,
    order,
    orderBy,
  };
};
